import { navigate } from "gatsby";
import { useEffect } from "react";

const PrimeHome = () => {
  useEffect(()=> {
    navigate(`/prime/registration`)
  }, [])
	return null;
}

export default PrimeHome
